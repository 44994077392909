import React, { useCallback, useEffect, useState } from 'react'
import Texts from '../components/texts/Texts';
import transition from '../utils/Transition';
import Share from '../components/functions/Share';
import Copy from '../components/functions/Copy';
import { useTransmissions } from '../hooks/useTransmission';
import Loading from '../utils/Loading';
import { CatchReturn } from '../utils/CatchReturn';
import { Link } from 'react-router-dom';
import { API_BASE_URL, URL_CMS } from '../services/services';
import { clickScroll } from '../utils/StartPage';
import axios from 'axios';
import BitmovinPlayer from './BitmovinPlayer';

const Transmission = () => {

    const { data: transmissions, isLoading, error } = useTransmissions();
    const transmissionActive = transmissions?.find(transmission => transmission.status === 'active');

    const [transmissionViews, setTransmissionViews] = useState(transmissionActive);

    useEffect(() => {
        if (transmissionActive) {
            setTransmissionViews(transmissionActive);
        }
    }, [transmissionActive]);

    const handlePlay = useCallback(() => {
        if (transmissionActive) {
            axios.post(`${API_BASE_URL}/transmissions/increment-views/${transmissionActive.id}`)
                .then(response => {
                    setTransmissionViews(prev => ({
                        ...prev,
                        views: prev.views + 1
                    }));
                })
                .catch(error => {
                });
        }
    }, [transmissionActive]);

    const handlePause = useCallback(() => {
        if (transmissionActive) {
            axios.post(`${API_BASE_URL}/transmissions/decrement-views/${transmissionActive.id}`)
                .then(response => {
                    setTransmissionViews(prev => ({
                        ...prev,
                        views: Math.max(0, prev.views - 1)
                    }));
                })
                .catch(error => {
                });
        }
    }, [transmissionActive]);

    useEffect(() => {
        return () => {
            if (transmissionActive) {
                handlePause();
            }
        };
    }, [transmissionActive, handlePause]);

    if (isLoading) return <Loading />;

    if (error || !transmissions) return <CatchReturn />;

    return (
        <>
            {transmissionActive && (
                <>
                    <Texts title={'Altavoz En Vivo'} />

                    <div className="p-4 sm:py-16 lg:pl-48 xl:pl-72 w-full bg-gray-100 text-black">

                        <div className="flex flex-col lg:flex-row gap-12">
                            {transmissionActive && (
                                <div className='w-full lg:w-7/12 flex-none flex  flex-col gap-4 place-self-start xl:place-self-center'>
                                    <Link to={`/audioteca/${transmissionActive.program.slug}`} onClick={clickScroll}>
                                        <article className='group flex items-center gap-2'>
                                            <figure className='relative w-16 h-16 rounded-lg shadow-black group-hover:shadow-2xl transition-all duration-500 group-hover:w-20 group-hover:h-20'>
                                                <img loading='lazy' src={`${URL_CMS}${transmissionActive.program.banner_path}`} alt={transmissionActive.program.name} className="w-full h-full object-cover rounded-lg" />
                                                <div className="absolute p-2 inset-0 flex justify-center items-center">
                                                    <img loading='lazy' src={`${URL_CMS}${transmissionActive.program.image_path}`} alt={transmissionActive.program.name} className="w-full h-full object-contain" />
                                                </div>
                                            </figure>
                                            <p className='text-base sm:text-xl'>{transmissionActive.program.name}</p>
                                        </article>
                                    </Link>
                                    <h2 className='text-2xl sm:text-4xl font-semibold'>{transmissionActive.name}</h2>
                                    <div className='group relative'>
                                        <BitmovinPlayer
                                            hls={`${transmissionActive.transmission_link}`}
                                            poster={`${URL_CMS}${transmissionActive.program.banner_path}`}
                                            handlePlay={handlePlay}
                                            handlePause={handlePause}
                                            title={transmissionActive.name}
                                        />
                                        <p dangerouslySetInnerHTML={{ __html: transmissionActive.synopsis }} className='mt-8 text-sm sm:text-lg font-medium'></p>

                                        <div className="p-2 absolute right-5 top-5 bg-black/70 rounded-lg flex items-center gap-4 -translate-y-4 transition-all duration-1000 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 z-10">
                                            <p className='text-white flex gap-1'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                    <path d="M9 10l.01 0" />
                                                    <path d="M15 10l.01 0" />
                                                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                                                </svg>
                                                {transmissionViews?.views}
                                            </p>
                                            <Copy url={window.location.href} />
                                            <Share url={window.location.href} section={"nuestra transmisión en vivo"} title={transmissionActive.name} type='live' />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="px-12 lg:px-4 lg:pt-24 w-full flex flex-col gap-8">
                                {transmissions.map(transmission => (
                                    <Link key={transmission.id} to={`/audioteca/${transmission.program.slug}`} onClick={clickScroll}>
                                        <article className={`w-full group flex gap-4 ${transition}`}>
                                            <div className='place-self-center'>
                                                <figure className='relative w-16 h-16 sm:w-20 sm:h-20 rounded-lg shadow-black group-hover:shadow-2xl'>
                                                    <img loading='lazy' src={`${URL_CMS}${transmission.program.banner_path}`} alt={transmission.program.name} className="w-full h-full object-cover rounded-lg" />

                                                    <div className="absolute p-2 inset-0 flex justify-center items-center">
                                                        <img loading='lazy' src={`${URL_CMS}${transmission.program.image_path}`} alt={transmission.program.name} className="w-full h-full object-contain" />
                                                    </div>

                                                    {transmission.status === 'active' && (
                                                        <div className="m-1 p-1 absolute -left-4 -top-4 flex justify-center items-center bg-red-500 rounded-md shadow-black group-hover:shadow-xl">
                                                            <p className='text-white text-xxs sm:text-xs font-medium'>En Vivo</p>
                                                        </div>
                                                    )}
                                                </figure>
                                            </div>

                                            <div className='flex flex-col justify-between gap-2'>
                                                <p className='text-base sm:text-xl'>{transmission.name}</p>
                                                <p className='text-xs sm:text-base'>{transmission.program.name}</p>
                                            </div>
                                        </article>
                                    </Link>
                                ))}
                            </div>
                        </div >
                    </div >
                </>
            )
            }
        </>
    )
}

export default Transmission
