export enum Player {
  BITMOVIN = 'bitmovin',
  BITMOVIN_PWX = 'bitmovin-pwx',
  JW = 'jw',
  RADIANT = 'radiant',
  VIDEOJS = 'videojs',
  HLSJS = 'hlsjs',
  SHAKA = 'shaka',
  DASHJS = 'dashjs',
  HTML5 = 'html5',
  CHROMECAST_SHAKA = 'chromecast-shaka',
  AMAZON_IVS = 'amazonivs',
}
