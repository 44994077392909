import React, { useEffect, useRef, useState } from 'react';
import { Player } from 'bitmovin-player';
import {PlayerModule as AnalyticsModule} from 'bitmovin-analytics';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import { PLAYER_KEY, ANALYTICS_KEY } from '../services/services';
import 'bitmovin-player/bitmovinplayer-ui.css';

Player.addModule(AnalyticsModule);

const BitmovinPlayer = ({ hls, poster, title, handlePlay, handlePause }) => {

    const [player, setPlayer] = useState(null);

    const playerConfig = {
        key: PLAYER_KEY,
        ui: false,
        analytics: {
            key: ANALYTICS_KEY,
            videoId: 'VIDEO_ID',
        },
    };
    const playerSource = {
        hls: hls,
        title: title
    };
    const playerDiv = useRef();

    useEffect(() => {
        function setupPlayer() {
            const playerInstance = new Player(playerDiv.current, playerConfig);
            UIFactory.buildDefaultUI(playerInstance);
            playerInstance.load(playerSource).then(() => {
                setPlayer(playerInstance)
            }).catch(() => {
                return null;
            });

            playerInstance.on('play', () => {
                handlePlay();
            });

            playerInstance.on('paused', () => {
                handlePause();
            });
        }

        setupPlayer();

        return () => {
            function destroyPlayer() {
                if (player != null) {
                    player.destroy();
                    setPlayer(null);
                }
            }

            destroyPlayer();
        }
    }, [])

    return (
        <div id='player' ref={playerDiv} className='rounded-lg shadow-2xl' />
    );
}


export default BitmovinPlayer