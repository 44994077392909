const ENVIRONMENT=''
const API_BASE_URL = process.env.API_BASE_URL||`https://${ENVIRONMENT=='beta'?'beta.':''}altavozradio.mx/api`;
const URL_CMS = process.env.URL_CMS||`https://${ENVIRONMENT=='beta'?'beta.':''}altavozradio.mx/cms`;




const BANNERS_ENDPOINT = '/banners';
const STATIONS_ENDPOINT = '/stations';
const STATIONS_RECOMENDED_ENDPOINT = '/stations/recomended';
const STATIONS_SEARCH_ENDPOINT = '/stations/search';
const COLLECTIVES_ENDPOINT = '/collectives';
const BROADCASTS_ENDPOINT = '/broadcasts';
const PODCASTS_ENDPOINT = '/podcasts';
const PODCASTS_RECOMENDED_ENDPOINT = '/podcasts/recomended';
const PODCASTS_SEARCH_ENDPOINT = '/podcasts/search';
const PLAYLISTS_ENDPOINT = '/playlists';
const SONGS_ENDPOINT = '/songs';
const HISTORIES_ENDPOINT = '/histories';
const DRAFT_ENDPOINT = '/create-draft';
const MUSIC_ENDPOINT = '/music';
const PROGRAMS_ENDPOINT = '/programs';
const PROGRAMS_RECOMENDED_ENDPOINT = '/programs/recomended';
const PROGRAMS_SEARCH_ENDPOINT = '/programs/search';
const ANNOUNCEMENTS_ENDPOINT = '/announcements';
const CONTACT_ENDPOINT = '/send-email';
const APISTATES = 'https://sepomex.icalialabs.com/api/v1/states';
const FACEBOOK = 'https://www.facebook.com/altavozmxradio';
const X = 'https://twitter.com/altavozmxradio';
const INSTAGRAM = 'https://www.instagram.com/altavozmxradio/';
const SPOTIFY = 'https://open.spotify.com/show/6FPxnTK9p5oVVX4fXL6nKu';
const PRIVACITY = 'https://www.spr.gob.mx/_pdf/transparencia/proteccion-datos/API_Contacto_Altavoz.pdf';
const PRIVACITY_HISTORIES = 'https://www.spr.gob.mx/_pdf/transparencia/proteccion-datos/API_Altavoz_Podcast.pdf';
const DEFENSORY = 'https://www.spr.gob.mx/defensoria/';
const INFO = 'https://www.spr.gob.mx/_pdf/radiodifusion/altavozradio/Infografia_Historias_Vivas.pdf';
const RECAPTCHA_SITE_KEY = '6Lf9_s8pAAAAAMjat8mQyuPLy4LZxi9zu7uroAkz';
const TRANSMISSIONS_ENDPOINT = '/transmissions';
const PLAYER_KEY = '8a7bd51d-b921-4788-b1e6-0160f95cb5ba';
const ANALYTICS_KEY = '184629c2-1100-47e1-87d3-51e00d508e6d';

export {
    API_BASE_URL,
    URL_CMS,
    ENVIRONMENT,
    BANNERS_ENDPOINT,
    STATIONS_ENDPOINT,
    STATIONS_RECOMENDED_ENDPOINT,
    STATIONS_SEARCH_ENDPOINT,
    COLLECTIVES_ENDPOINT,
    BROADCASTS_ENDPOINT,
    PODCASTS_ENDPOINT,
    PODCASTS_RECOMENDED_ENDPOINT,
    PODCASTS_SEARCH_ENDPOINT,
    PLAYLISTS_ENDPOINT,
    SONGS_ENDPOINT,
    HISTORIES_ENDPOINT,
    DRAFT_ENDPOINT,
    MUSIC_ENDPOINT,
    PROGRAMS_ENDPOINT,
    PROGRAMS_RECOMENDED_ENDPOINT,
    PROGRAMS_SEARCH_ENDPOINT,
    ANNOUNCEMENTS_ENDPOINT,
    CONTACT_ENDPOINT,
    APISTATES,
    FACEBOOK,
    X,
    INSTAGRAM,
    SPOTIFY,
    PRIVACITY,
    PRIVACITY_HISTORIES,
    DEFENSORY,
    INFO,
    RECAPTCHA_SITE_KEY,
    TRANSMISSIONS_ENDPOINT,
    PLAYER_KEY,
    ANALYTICS_KEY
};
