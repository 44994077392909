import type {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import type {Feature} from '../../features/Feature';
import type {FeatureConfig} from '../../features/FeatureConfig';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import type {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {normalizeVideoDuration, PlaybackInfo} from '../../types/PlaybackInfo';
import {InternalAdapter} from '../internal/InternalAdapter';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {BitmovinPwxAnalyticsStateMachine} from './BitmovinPwxAnalyticsStateMachine';
import {
  CoreExportNames,
  PlaybackApiEventsPackageExportNames,
  SourceExportNames,
  SourcesApiExportNames,
} from './ExportNames';
import {AnalyticsContext} from './Types';

const PWX_ABORT_ERROR_CODE = 1007;

export class BitmovinPwxInternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private onBeforeUnLoadEvent = false;

  constructor(
    private readonly context: AnalyticsContext,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(opts);
    this.stateMachine = new BitmovinPwxAnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
  }

  private getSource() {
    return this.context.registry.get(SourceExportNames.SourceState);
  }

  protected get currentTime(): number {
    return this.getSource().playback.playhead.position;
  }

  getAutoPlay(): boolean {
    return this.getSource().config.playback.autoplay;
  }

  getCurrentPlaybackInfo(): PlaybackInfo {
    const hls = this.getSource().sourceConfig.resources.filter((v) => v.url.includes('.m3u8'))[0]?.url;
    const dash = this.getSource().sourceConfig.resources.filter((v) => v.url.includes('.mpd'))[0]?.url;

    const videoRect = this.getSource().video.element?.getBoundingClientRect();
    const videoWidth = Math.round(videoRect?.width ?? 1);
    const videoHeight = Math.round(videoRect?.height ?? 1);

    let streamFormat = 'unknown';
    if (hls) {
      streamFormat = 'hls';
    } else if (dash) {
      streamFormat = 'dash';
    }

    // height, width, codecs and bitrate are fixed until we have quality api
    return {
      droppedFrames: 0,
      isMuted: this.getSource().video.element?.muted,
      isLive: this.getSource().stream.type === 'live',
      videoWindowHeight: videoHeight,
      videoWindowWidth: videoWidth,
      videoDuration: normalizeVideoDuration(this.getSource().video.element?.duration),
      videoPlaybackHeight: videoHeight,
      videoPlaybackWidth: videoWidth,
      m3u8Url: hls,
      mpdUrl: dash,
      streamFormat,
      videoCodec: undefined, // not tracked yet
      audioCodec: undefined, // not tracked yet
      videoBitrate: 0, // not tracked yet
      audioBitrate: 0, // not tracked yet
    };
  }

  getDrmPerformanceInfo(): DrmPerformanceInfo | undefined {
    return undefined;
  }

  getPlayerName(): string {
    return Player.BITMOVIN_PWX;
  }

  getPlayerTech(): string {
    return 'webx:html5';
  }

  getPlayerVersion(): string {
    return this.context.registry.get(CoreExportNames.Constants).Version;
  }

  initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    this.registerEventListeners();
    this.registerUnloadEventListeners();

    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );

    return [errorDetailTracking];
  }

  private registerUnloadEventListeners() {
    let handleCollectorUnload = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        const currentTime = this.context.registry.get(SourceExportNames.SourceState).playback.playhead.position;
        this.eventCallback(Event.UNLOAD, {currentTime});
      }
      this.release();
    };
    handleCollectorUnload = handleCollectorUnload.bind(true);

    this.context.abortSignal.addEventListener('abort', handleCollectorUnload);
    this.windowEventTracker.addEventListener('beforeunload', handleCollectorUnload);
    this.windowEventTracker.addEventListener('unload', handleCollectorUnload);
  }

  private registerEventListeners() {
    // The context might not be same so a different source event bus is available
    const sourceEventBus = this.context.registry.get(SourcesApiExportNames.SourceEventBus);
    const playbackAtom = this.context.registry.get(SourceExportNames.SourceState).playback;
    const PlaybackEvent = this.context.registry.get(PlaybackApiEventsPackageExportNames.PlaybackEvent);

    sourceEventBus.on(PlaybackEvent.Ready, () => {
      this.eventCallback(Event.SOURCE_LOADED, {currentTime: playbackAtom.playhead.position});
    });
    sourceEventBus.on(PlaybackEvent.Play, () => {
      this.eventCallback(Event.PLAY, {currentTime: playbackAtom.playhead.position});
    });
    sourceEventBus.on(PlaybackEvent.Playing, () => {
      this.eventCallback(Event.PLAYING, {currentTime: playbackAtom.playhead.position});
    });
    sourceEventBus.on(PlaybackEvent.Paused, () => {
      this.eventCallback(Event.PAUSE, {currentTime: playbackAtom.playhead.position});
    });
    sourceEventBus.on(PlaybackEvent.TimeChanged, () => {
      this.eventCallback(Event.TIMECHANGED, {currentTime: playbackAtom.playhead.position});
    });
    sourceEventBus.on(PlaybackEvent.VideoAttached, () => {
      this.eventCallback(Event.SOURCE_LOADED, {currentTime: playbackAtom.playhead.position});
    });

    sourceEventBus.on(PlaybackEvent.Ended, () => {
      this.eventCallback(Event.END, {currentTime: playbackAtom.playhead.position});
    });

    const abortListener = () => {
      const abortSignalReason = this.context.abortSignal.reason;
      if (abortSignalReason.name === 'SourceRemovedError' || abortSignalReason.name === 'FrameworkDisposedError') {
        this.eventCallback(Event.SOURCE_UNLOADED, {currentTime: playbackAtom.playhead.position});
      } else {
        this.eventCallback(Event.ERROR, {
          currentTime: playbackAtom.playhead.position,
          code: PWX_ABORT_ERROR_CODE,
          message: abortSignalReason.message,
          data: abortSignalReason,
        });
      }

      this.context.abortSignal.removeEventListener('abort', abortListener);
    };

    this.context.abortSignal.addEventListener('abort', abortListener);
  }

  sourceChange(_: AnalyticsConfig) {
    // Every time a source is added, a new instance of analytics package is triggered. So technically there are
    // no source changes. Also, a single player instance can handle infinite amount of sources at once.
    //
    // We are aware, at the moment we are handling it as "every source = one session". We will need to do
    // some additional work in the future (once we figure out how this part should work) to handle multiple
    // sources in one session (e.g. ads).
    //
    // What we mean by "no source changes", is that you do not have API to change "current playing" source
    // to something else. You would need to add new source and remove old one (or keep it). One instance can
    // have infinite amount of sources, each source for us is a new analytics session (for now, in certain cases
    // they should be tied together - e.g. ads)
    //
    // @see https://github.com/bitmovin-engineering/bitmovin-analytics-collector/pull/867#discussion_r1660699646

    throw new Error('Method not implemented.');
  }
}
